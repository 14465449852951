import React, { useState } from 'react'
import PokerLayout from '../../../components/Poker/PokerLayout'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { POKER_HORSE, POKER, POKER_VARIANTS } from '../../../components/internal-links'
import '../../../components/Games/Cards/Poker/Poker.scss'
import { graphql, useStaticQuery } from 'gatsby'
import RenderHTML from '../../../components/Poker/PokerVarientPages/RenderHTML'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
} from '../../../utils/constnst.utils'

const PokerPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(filter: { slug: { eq: "how-to-play-horse-poker" } }) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const title = data.allGhostPost.nodes[0]?.title
  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: POKER_BREADCRUMB_TITLE,
      url: POKER,
    },
    {
      title: 'Poker Variations',
      url: POKER_VARIANTS
    },
    {
      title: title,
      url: POKER_HORSE,
    },
  ]

  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  return (
    <>
      <PokerLayout
        pageName="custom"
        breadcrumbs={breadcrumbs}
        pageSlug={{
          POKER_LINK: POKER_HORSE,
        }}
        seoDetails={{
          title: metaTitle,
          description: metaDescription,
        }}
        mainBannerTitle={title}
        englishContentHTML={
          <RenderHTML html={data.allGhostPost.nodes[0]?.html} />
        }
        pokerVariants={[
          {
            name: 'Horse Poker',
          },
        ]}
      />
    </>
  )
}

export default PokerPage
